import chatModule from '../chatModule';

function detailsController($scope, $log) {
  'ngInject';

  const initator = $scope.dialogData.initiator;
  const currentUserId = $scope.chatLibrary.userId;
  $scope.canConversationRemoveMember = function() {
    if ($scope.dialogData.dialogType !== $scope.dialogType.CHAT) {
      return initator && initator.id === currentUserId;
    }
    return false;
  };
}

chatModule.controller('detailsController', detailsController);

export default detailsController;
