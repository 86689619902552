import angular from 'angular';
import chatModule from '../chatModule';

function messageService(
  chatService,
  chatUtility,
  $rootScope,
  $filter,
  $log,
  systemMessages,
  $timeout,
  messageHelper,
  gameUtility,
  gameService,
  messageUtility,
  dialogAttributes,
  usersService
) {
  'ngInject';

  const oneDay = 24 * 60 * 60 * 1000;
  let partyChromeDisplayTimeStampInterval = 30000;
  function parseMessageTimestamp(message) {
    if (message.sent && !message.parsedTimestamp) {
      message.parsedTimestamp = new Date(message.sent).getTime();
    }
  }

  function formatTimeStamp(message, isBriefVersion, currentDate) {
    const timeStamp = message.parsedTimestamp;
    const now = angular.isDefined(currentDate) ? currentDate : new Date();
    const yesterday = new Date(now - oneDay);
    const messageDateObj = new Date(timeStamp);
    const messageDate = messageDateObj.toDateString();
    const diffDays = Math.round(Math.abs(now.getTime() - messageDateObj.getTime()) / oneDay);
    const messageDay = messageDateObj.getDay();
    const currentYear = now.getFullYear();
    const messageYear = messageDateObj.getFullYear();
    let timeFormat = 'h:mm a';
    // same day
    if (now.toDateString() === messageDate) {
      const displayTimeStamp = $filter('date')(timeStamp, timeFormat);
      if (isBriefVersion) {
        message.briefTimeStamp = displayTimeStamp;
      } else {
        message.displayTimeStamp = displayTimeStamp;
      }
    } else if (yesterday.toDateString() === messageDate) {
      // yesterday
      if (isBriefVersion) {
        message.briefTimeStamp = 'Yesterday';
      } else {
        message.displayTimeStamp = `Yesterday | ${  $filter('date')(timeStamp, timeFormat)}`;
      }
    } else if (diffDays <= messageDay) {
      // with one week
      if (isBriefVersion) {
        timeFormat = 'EEE';
        message.briefTimeStamp = $filter('date')(timeStamp, timeFormat);
      } else {
        timeFormat = `EEE | ${  timeFormat}`;
        message.displayTimeStamp = $filter('date')(timeStamp, timeFormat);
      }
    } else if (currentYear === messageYear) {
      if (isBriefVersion) {
        timeFormat = 'MMM d';
        message.briefTimeStamp = $filter('date')(timeStamp, timeFormat);
      } else {
        timeFormat = `MMM d | ${  timeFormat}`;
        message.displayTimeStamp = $filter('date')(timeStamp, timeFormat);
      }
    } else if (isBriefVersion) {
      timeFormat = 'MMM d, yyyy';
      message.briefTimeStamp = $filter('date')(timeStamp, timeFormat);
    } else {
      timeFormat = 'MMM d, yyyy | ' + timeFormat;
      message.displayTimeStamp = $filter('date')(timeStamp, timeFormat);
    }
  }

  function categorizeMessageType(chatLibrary, messages, conversation) {
    if (messages && messages.length > 0) {
      const universeIdsNotInPlaceLibrary = [];
      const sizeOfMsg = messages.length;
      for (let i = sizeOfMsg - 1; i >= 0; i--) {
        const message = messages[i];
        message.linkCardMessages = [];
        const { messageTypes } = messageHelper;
        switch (message.messageType) {
          case messageTypes.link.name:
            message.hasLinkCard = true;
            var { universeId } = message.link.game;
            var placeId = gameUtility.isGameExistedInPlacesLibrary(
              chatLibrary.placesLibrary,
              universeId
            );
            if (!placeId) {
              universeIdsNotInPlaceLibrary.push(universeId);
            }
            var pieceOfMsg = {
              universeId,
              isLinkCard: true
            };
            message.linkCardMessages.push(pieceOfMsg);
            break;
          case messageTypes.eventBased.name:
            message.isSystemMessageFromApi = true;
            messageUtility.setSystemMessage(message);
            break;
          case messageTypes.plainText.name:
            chatUtility.sanitizeMessage(message);
            break;
        }
      }
      if (chatLibrary.isRespectingMessageTypeEnabled && universeIdsNotInPlaceLibrary.length > 0) {
        gameService.getGames(universeIdsNotInPlaceLibrary).then(function success(placesData) {
          gameService.buildPlacesLibrary(chatLibrary, placesData);
          const { placesLibrary } = chatLibrary;
          gameService.buildButtonLayoutPerConversation(conversation, placesLibrary);
        });
      }
    }
  }

  // Use this object to mark messages read...it will queue messages so we don't send frequent calls for the same conversation
  let markMessagesRead = (function() {
    let messagesToMarkRead = {};
    let timer = false;

    function queueMessageToMarkRead(conversation, latestMessageId) {
      messagesToMarkRead[conversation.id] = {
        conversation,
        latestMessageId
      };
      if (timer === false) {
        timer = true;
        $timeout(doMarkMessagesRead, 1000);
      }
    }

    function doMarkMessagesRead() {
      for (const conversationId in messagesToMarkRead) {
        var savedData = messagesToMarkRead[conversationId];
        chatService.markAsRead(conversationId, savedData.latestMessageId).then(
          function(data) {
            if (data) {
              let conversation = savedData.conversation;
              conversation.hasUnreadMessages = false;
              conversation.unreadMessageIds = [];
              conversation.unreadMessageTimestamps = [];
              if (savedData.latestMessageId !== null) {
                conversation.pendingUnreadMessageId.splice(savedData.latestMessageId, 1);
              }
              $rootScope.$broadcast('Roblox.Chat.LoadUnreadConversationCount');
            }
          },
          function() {
            $log.debug('----- markAsRead request is failed ! ------');
          }
        );
      }
      messagesToMarkRead = {};
      timer = false;
    }

    return {
      queueMessageToMarkRead
    };
  })();

  function isUserInfoExisted(message, friendsDict, alienIds) {
    if (message.senderType !== messageHelper.senderTypes.user) {
      return false;
    }
    return (
      friendsDict &&
      !friendsDict[message.senderTargetId] &&
      alienIds.indexOf(message.senderTargetId) < 0
    );
  }

  return {
    setParams(data) {
      partyChromeDisplayTimeStampInterval = parseInt(data.partyChromeDisplayTimeStampInterval);
    },

    setFallbackClusterMaster(conversation, message, isOld) {
      if (angular.isUndefined(conversation.chatMessages)) {
        conversation.chatMessages = [];
      }
      // message is sent by different sender with previous sender
      // message is sent as different timestamp
      let index = conversation.chatMessages.length - 1;
      if (message.displayTimeStamp) {
        message.isClusterMaster = true;
      }
      if (
        conversation.chatMessages.length > 0 &&
        conversation.chatMessages[index].senderTargetId !== message.senderTargetId
      ) {
        conversation.chatMessages[index].isClusterMaster = true;
      }

      conversation.chatMessages.push(message);
    },
    setClusterMaster(conversation, message) {
      if (angular.isUndefined(conversation.chatMessages)) {
        conversation.chatMessages = [];
      }
      // message is sent by different sender with previous sender
      // message is sent as different timestamp
      if (
        (conversation.chatMessages.length > 0 &&
          conversation.chatMessages[0].senderTargetId !== message.senderTargetId) ||
        message.displayTimeStamp
      ) {
        message.isClusterMaster = true;
      }

      if (!message.resetClusterMessage) {
        conversation.chatMessages.unshift(message);
      }
    },

    buildFallbackTimeStamp(message, conversation, currentDate) {
      if (!message.sent) {
        return false;
      }
      parseMessageTimestamp(message);
      let timeStamp = message.parsedTimestamp;
      if (
        !conversation.startTimeStamp ||
        timeStamp + partyChromeDisplayTimeStampInterval < conversation.startTimeStamp
      ) {
        formatTimeStamp(message, false, currentDate);
        conversation.startTimeStamp = timeStamp;
      }
    },

    buildTimeStamp(message, conversation, currentDate) {
      if (!message.sent) {
        return false;
      }
      parseMessageTimestamp(message);
      let timeStamp = message.parsedTimestamp;
      if (!conversation.previousTimeStamp) {
        conversation.startTimeStamp = timeStamp;
      }
      if (
        !conversation.previousTimeStamp ||
        timeStamp - partyChromeDisplayTimeStampInterval > conversation.previousTimeStamp
      ) {
        formatTimeStamp(message, false, currentDate);
        conversation.previousTimeStamp = timeStamp;
      }
      return true;
    },

    updateContentForInvalidMessageType(chatLibrary, messages) {
      angular.forEach(messages, function(message) {
        var isMessageTypeInWhiteList = messageUtility.isMessageTypeInWhiteList(
          chatLibrary,
          message
        );
        if (!isMessageTypeInWhiteList) {
          message.content = chatLibrary.errors.unknownMessageType;
        }
      });
    },

    preProcessMessages(chatLibrary, conversation, messages) {
      this.updateContentForInvalidMessageType(chatLibrary, messages);
      if (chatLibrary.isRespectingMessageTypeEnabled) {
        categorizeMessageType(chatLibrary, messages, conversation);
      } else {
        chatUtility.sanitizeMessages(messages);
      }
    },

    processMessages(chatLibrary, conversation, messages, friendsDict) {
      this.preProcessMessages(chatLibrary, conversation, messages);
      this.manipulateMessages(conversation, messages, friendsDict);
    },

    // buid message dictionary and update friends dictionary
    manipulateMessages(conversation, messages, friendsDict) {
      if (!messages) {
        conversation.messagesDict = {};
        conversation.unreadMessageIds = [];
        conversation.unreadMessageTimestamps = [];
      }

      if (angular.isUndefined(conversation.messagesDict)) {
        conversation.messagesDict = {};
      }
      if (angular.isUndefined(conversation.unreadMessageIds)) {
        conversation.unreadMessageIds = [];
        conversation.unreadMessageTimestamps = [];
      }

      if (messages && messages.length > 0) {
        let sizeOfMsg = messages.length;
        let alienIds = [];
        conversation.previousTimeStamp = null;
        for (var i = sizeOfMsg - 1; i >= 0; i--) {
          let message = messages[i];
          this.buildTimeStamp(message, conversation);
          if (!conversation.messagesDict[message.id]) {
            // chatUtility.sanitizeMessage(message);
            conversation.messagesDict[message.id] = message;
            this.setClusterMaster(conversation, message);
            if (!message.read) {
              conversation.unreadMessageIds.push(message.id);
              conversation.unreadMessageTimestamps.push(message.parsedTimestamp);
            }
          }
          // check the sender user info
          if (isUserInfoExisted(message, friendsDict, alienIds)) {
            let senderId = message.senderTargetId;
            $log.debug(
              ' ----- new friend information for this message, trying to get now -----' + senderId
            );
            let userIds = [senderId];
            alienIds.push(senderId);
            usersService.getUserInfo(userIds, friendsDict);
          }
        }
        if (conversation.unreadMessageIds.length > 0) {
          $rootScope.$broadcast('Roblox.Chat.LoadUnreadConversationCount');
        }
      }
    },

    formatTimestampInConversation(conversation) {
      if (!conversation.briefTimeStamp) {
        conversation.parsedTimestamp = new Date(conversation.lastUpdated).getTime();
        formatTimeStamp(conversation, true);
      }
    },

    // attach message to existing conversation
    appendMessages(chatLibrary, conversation, messages) {
      if (!messages) {
        return false;
      }
      if (angular.isUndefined(conversation.unreadMessageIds)) {
        conversation.unreadMessageIds = [];
        conversation.unreadMessageTimestamps = [];
      }

      this.updateContentForInvalidMessageType(chatLibrary, messages);
      if (chatLibrary.isRespectingMessageTypeEnabled) {
        categorizeMessageType(chatLibrary, messages, conversation);
      } else {
        chatUtility.sanitizeMessages(messages);
      }
      if (!conversation.chatMessages || conversation.chatMessages.length === 0) {
        // never have message before
        var sizeOfMsg = messages.length;
        for (var i = sizeOfMsg - 1; i >= 0; i--) {
          var message = messages[i];
          this.buildTimeStamp(message, conversation);
          this.setClusterMaster(conversation, message);
        }
        conversation.chatMessages = messages;
      } else if (conversation.chatMessages) {
        let currentLatestMsg = {}; // set default
        for (var i = 0; i < conversation.chatMessages.length; i++) {
          let chatMessage = conversation.chatMessages[i];
          if (
            !chatMessage.isSystemMessage &&
            !chatMessage.sendMessageHasError &&
            !chatMessage.resetClusterMessage
          ) {
            // not system message, not problem message, not sent message
            currentLatestMsg = conversation.chatMessages[i];
            parseMessageTimestamp(currentLatestMsg);
            break;
          }
        }
        var sizeOfMsg = messages.length;
        for (var i = sizeOfMsg - 1; i >= 0; i--) {
          var message = messages[i];
          parseMessageTimestamp(message);
          var isCurrentMessageSameAsTheLatestMessage =
            message.id === currentLatestMsg.id ||
            (currentLatestMsg.id &&
              typeof currentLatestMsg.id !== 'string' &&
              currentLatestMsg.id.toString() === message.id);
          var isMessagePresentInMessagesDict =
            !angular.isUndefined(conversation.messagesDict) &&
            !angular.isUndefined(message.id) &&
            !angular.isUndefined(conversation.messagesDict[message.id]);
          if (
            (angular.equals({}, currentLatestMsg) ||
              message.parsedTimestamp > currentLatestMsg.parsedTimestamp) &&
            !isCurrentMessageSameAsTheLatestMessage &&
            !isMessagePresentInMessagesDict
          ) {
            this.buildTimeStamp(message, conversation);
            this.setClusterMaster(conversation, message);

            conversation.messagesDict[message.id] = message;
          }
          if (!message.read) {
            conversation.hasUnreadMessages = true;
            conversation.unreadMessageIds.push(message.id);
            conversation.unreadMessageTimestamps.push(message.parsedTimestamp);
          }
        }
      }

      conversation.displayMessage = this.getDisplayMessageForUser(messages);
      if (conversation.unreadMessageIds.length > 0) {
        $rootScope.$broadcast('Roblox.Chat.LoadUnreadConversationCount');
      }
    },

    markMessagesAsRead(
      conversation,
      shouldRespectConversationHasUnreadMessageToMarkAsRead
    ) {
      if (conversation.chatMessages && conversation.unreadMessageIds) {
        let messages = conversation.chatMessages;
        let sizeOfMsg = messages.length;
        if (
          conversation.unreadMessageTimestamps.length > 0 &&
          conversation.unreadMessageTimestamps[0] >= messages[sizeOfMsg - 1].parsedTimestamp
        ) {
          let sizeOfUnreadMsg = conversation.unreadMessageIds.length;
          let latestMessageId = conversation.unreadMessageIds[sizeOfUnreadMsg - 1];
          if (angular.isUndefined(conversation.pendingUnreadMessageId)) {
            conversation.pendingUnreadMessageId = [];
          }
          if (conversation.pendingUnreadMessageId.indexOf(latestMessageId) < 0) {
            conversation.pendingUnreadMessageId.push(latestMessageId);
            markMessagesRead.queueMessageToMarkRead(conversation, latestMessageId);
          }
        }
      } else if (
        conversation.unreadMessageIds &&
        conversation.unreadMessageIds.length === 0 &&
        shouldRespectConversationHasUnreadMessageToMarkAsRead &&
        conversation.hasUnreadMessages
      ) {
        markMessagesRead.queueMessageToMarkRead(conversation, null);
      }
    },

    buildSystemMessage(notificationType, conversation, isErrorMsg) {
      let systemMessage = angular.copy(dialogAttributes.systemMessage);
      messageUtility.setSystemMessage(systemMessage, isErrorMsg);
      switch (notificationType) {
        case chatUtility.notificationType.conversationTitleModerated:
          systemMessage.content = chatUtility.errorMessages.conversationTitleModerated;
          break;
        case chatUtility.notificationType.conversationTitleChanged:
          var conversationTitle = chatUtility.htmlEntities(conversation.title);
          systemMessage.content = $filter('formatString')(
            chatUtility.chatLayout.conversationTitleChangedText,
            { userName: conversation.actorUsername, groupName: conversationTitle }
          );
          break;
        case chatUtility.notificationType.conversationUniverseChanged:
          systemMessage.content = systemMessages.playTogether.pinGameUpdate(
            conversation.pinGame.actorUsername,
            conversation.pinGame.encodedPlaceName
          );
          break;
        case chatUtility.notificationType.presenceOnline:
          systemMessage.content = systemMessages.playTogether.playGameUpdate;
          systemMessage.hasParams = true;
          break;
      }

      if (angular.isUndefined(conversation.chatMessages)) {
        conversation.chatMessages = [];
      }
      parseMessageTimestamp(systemMessage);
      this.setClusterMaster(conversation, systemMessage);
    },

    resetConversationUnreadStatus(conversation, messages) {
      if (messages.length === 0 && conversation.hasUnreadMessages) {
        markMessagesRead.queueMessageToMarkRead(conversation, null);
      }
    },

    getDisplayMessageForUser(messages) {
      let displayMessage = {};
      if (messages) {
        let {length} = messages;
        for (let i = 0; i < length; i++) {
          if (messages[i].senderType === messageHelper.senderTypes.user) {
            displayMessage = messages[i];
            displayMessage = this.buildDisplayMessage(displayMessage);
            return displayMessage;
          }
        }
      }
      return displayMessage;
    },

    buildDisplayMessage(message, currentDate) {
      parseMessageTimestamp(message);
      formatTimeStamp(message, true, currentDate);
      return message;
    },

    refreshTypingStatus(conversation, userIdForTyping, status, dialogLayout) {
      if (
        conversation &&
        status &&
        conversation.conversationType === chatUtility.conversationType.multiUserConversation
      ) {
        let messages = conversation.chatMessages;
        if (messages && messages.length > 0) {
          let userTyping = dialogLayout.typing.userTypingDict[userIdForTyping];
          let clusterMessagesMarkedAsTyping = {};
          for (let i = 0; i < messages.length; i++) {
            let message = messages[i];
            if (
              message.isClusterMaster &&
              message.senderTargetId === userIdForTyping &&
              (!clusterMessagesMarkedAsTyping[userIdForTyping] ||
                clusterMessagesMarkedAsTyping[userIdForTyping].messageId !== message.id)
            ) {
              userTyping.messageId = message.id;
              break;
            }
          }
        }
      }
    },

    categorizeMessageType
  };
}

chatModule.factory('messageService', messageService);

export default messageService;
