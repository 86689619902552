import { DisplayNames } from 'Roblox';
import angular from 'angular';
import chatModule from '../chatModule';

function friendsController($scope, chatUtility, usersService, usersPresenceService, $timeout) {
  'ngInject';

  $scope.dialogLayout.scrollToBottom = false;
  $scope.dialogLayout.IsdialogContainerVisible = false;
  $scope.dialogParams = { ...chatUtility.dialogParams };
  $scope.dialogType = { ...chatUtility.dialogType };
  $scope.userPresenceTypes = { ...chatUtility.userPresenceTypes };
  $scope.friendsScrollbarElm = chatUtility.getScrollBarSelector(
    $scope.dialogData,
    chatUtility.scrollBarType.FRIENDSELECTION
  );
  $scope.dialogData.scrollBarType = chatUtility.scrollBarType.FRIENDSELECTION;

  $scope.dialogData.isCreated = true;

  $scope.updateFriendsDictData = friends => {
    if (friends?.length) {
      const userIds = [];
      angular.forEach(friends, friend => {
        const currentFriend = friend;
        const { id: userId } = currentFriend;
        currentFriend.id = parseInt(userId, 10);
        const { name, displayName } = currentFriend;
        currentFriend.nameForDisplay = DisplayNames?.Enabled() ? displayName : name;

        if (!$scope.chatLibrary.friendsDict[userId]) {
          $scope.chatLibrary.friendsDict[userId] = currentFriend;
          userIds.push(userId);
        }
      });

      $scope.updateFriends(friends);
      if (userIds && userIds.length > 0) {
        usersService.getAvatarHeadshots(userIds, $scope.chatLibrary.friendsDict);
      }
    }
  };

  const getFriends = function () {
    usersPresenceService.getFriendsPresence().then(
      result => {
        if (result?.length) {
          $scope.updateFriendsDictData(result);
        }
      },
      error => {
        console.debug(error);
      }
    );
  };

  if ($scope.chatLibrary.friendIds.length > 0) {
    $scope.updateFriends();
  }
  getFriends();
  $scope.isOverLoaded();
}

chatModule.controller('friendsController', friendsController);

export default friendsController;
