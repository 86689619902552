import chatModule from '../chatModule';

function friendsLazyLoad($log) {
  'ngInject';

  return {
    restrict: 'A',
    scope: true,
    link(scope, element, attrs) {
      const callbackLazyLoad = function() {
        if (!scope.dialogParams.loadMoreFriends) {
          return false;
        }
        scope.dialogLayout.isChatLoading = true;
      };

      element.mCustomScrollbar({
        autoExpandScrollbar: false,
        scrollInertia: 5,
        contentTouchScroll: 1,
        mouseWheel: {
          preventDefault: true
        },
        callbacks: {
          onTotalScroll: callbackLazyLoad,
          onOverflowYNone: callbackLazyLoad
        }
      });
    }
  };
}

chatModule.directive('friendsLazyLoad', friendsLazyLoad);

export default friendsLazyLoad;
