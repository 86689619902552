import chatModule from '../chatModule';

function dialogMessagesController($scope, $log, messageUtility) {
  'ngInject';

  $scope.isSenderTypeAllowed = function(message) {
    return (
      $scope.chatLibrary.senderTypesForUnknownMessageTypeError.indexOf(message.senderType) > -1
    );
  };

  $scope.canRenderMessage = function(message) {
    if (message.sendingMessage) {
      return true;
    }
    if (!$scope.chatLibrary.isRespectingMessageTypeEnabled) {
      return true;
    }

    return (
      message.isSystemMessage ||
      (messageUtility.isMessageTypeInWhiteList($scope.chatLibrary, message) &&
        messageUtility.isMessageTypeLegal(message) &&
        messageUtility.isSenderTypeLegal(message))
    );
  };

  $scope.shouldShowInvalidMessageTypePrompt = function(message) {
    return (
      $scope.chatLibrary.isInvalidMessageTypeFallbackEnabled && $scope.isSenderTypeAllowed(message)
    );
  };
}

chatModule.controller('dialogMessagesController', dialogMessagesController);

export default dialogMessagesController;
