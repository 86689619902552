import chatModule from '../chatModule';

function dialogLazyLoad(chatService, chatUtility, messageService, gameService, $log) {
  'ngInject';

  return {
    restrict: 'A',
    scope: true,
    link(scope, element, attrs) {
      scope.callbackScrollToBottom = function() {
        scope.updateDialog();
      };

      scope.callbackLazyLoad = function() {
        if (!scope.dialogParams.loadMoreMessages || !scope.dialogLayout.IsdialogContainerVisible) {
          return false;
        }
        scope.dialogLayout.isChatLoading = true;
        const sizeOfMessages = scope.dialogData.chatMessages.length;
        chatService
          .getMessages(
            scope.dialogData.id,
            scope.dialogData.chatMessages[sizeOfMessages - 1].id,
            scope.dialogParams.pageSizeOfGetMessages
          )
          .then(
            function(data) {
              scope.dialogLayout.isChatLoading = false;
              if (!data) {
                scope.dialogParams.loadMoreMessages = false;
                messageService.processMessages(
                  scope.chatLibrary,
                  scope.dialogData,
                  data,
                  scope.chatLibrary.friendsDict
                );
                gameService.fetchDataForLinkCard(data, scope.chatLibrary);
              } else {
                if (data.length > 0) {
                  scope.dialogLayout.scrollToBottom = false;

                  messageService.preProcessMessages(scope.chatLibrary, scope.dialogData, data);
                  for (let i = 0; i < data.length; i++) {
                    messageService.buildFallbackTimeStamp(data[i], scope.dialogData);
                    messageService.setFallbackClusterMaster(scope.dialogData, data[i]);
                  }
                  gameService.fetchDataForLinkCard(data, scope.chatLibrary);
                }
                if (data.length < scope.dialogParams.pageSizeOfGetMessages) {
                  scope.dialogParams.loadMoreMessages = false;
                }
              }
            },
            function() {
              scope.dialogLayout.isChatLoading = false;
              $log.debug('---error from get getMessages in dialogLazyLoadDirective.js---');
            }
          );
      };

      let init = function() {
        $log.debug('---- onInit callback ---- Scrollbars updated');
        scope.dialogLayout.scrollToBottom = true;
      };

      element.mCustomScrollbar({
        autoExpandScrollbar: false,
        scrollInertia: 5,
        contentTouchScroll: 1,
        mouseWheel: {
          preventDefault: true
        },
        callbacks: {
          onInit: init,
          onUpdate() {
            $log.debug(
              '---- onUpdate callback ---- Scrollbars updated' + scope.dialogLayout.scrollToBottom
            );
            if (scope.dialogLayout.scrollToBottom) {
              element.mCustomScrollbar('scrollTo', 'bottom', {
                scrollInertia: 0
              });
            } else {
              scope.dialogLayout.scrollToBottom = true;
            }

            if (element.hasClass('mCS_no_scrollbar')) {
              // equivalent to (element.has(":mcsOverflow") && !element.is(":mcsOverflow"))
              scope.updateDialog();
            }
          },
          onTotalScroll: scope.callbackScrollToBottom,
          onTotalScrollOffset: 60,
          onTotalScrollBack: scope.callbackLazyLoad
        }
      });
    }
  };
}

chatModule.directive('dialogLazyLoad', dialogLazyLoad);

export default dialogLazyLoad;
