import { Lang } from 'Roblox';
import angular from 'angular';

const dependencies = [
  'robloxApp',
  'monospaced.elastic',
  'modal',
  'ui.bootstrap.popover',
  'toast',
  'thumbnails'
];
const contactsModule = 'contacts';

try {
  angular.module(contactsModule);
  dependencies.push(contactsModule);
} catch (err) {}

const chat = angular.module('chat', dependencies).config([
  'msdElasticConfig',
  'languageResourceProvider',
  function (msdElasticConfig, languageResourceProvider) {
    msdElasticConfig.append = '\n';
    if (Lang.ChatResources) {
      languageResourceProvider.setLanguageKeysFromFile(Lang.ChatResources);
    }
  }
]);

export default chat;
