import chatModule from '../chatModule';

function lazyLoad(
  chatService,
  chatUtility,
  $log,
  $document,
  conversationsUtility,
  usersService,
  contactsService,
  usersPresenceService
) {
  'ngInject';

  return {
    restrict: 'A',
    scope: true,
    link(scope, element, attrs) {
      const getFriends = function () {
        usersPresenceService.getFriendsPresence().then(
          result => {
            if (result?.length) {
              scope.getFriendsInfo(result);
            }
          },
          error => {
            console.debug(error);
          }
        );
        scope.chatLibrary.chatLayout.isChatLoading = false;
        scope.chatApiParams.loadMoreFriends = false;
      };
      scope.callbackLazyLoad = function () {
        if (
          !scope.chatApiParams ||
          scope.chatLibrary.chatLayout.errorMaskEnable ||
          (!scope.chatApiParams.loadMoreConversations && !scope.chatApiParams.loadMoreFriends)
        ) {
          return false;
        }
        scope.chatLibrary.chatLayout.isChatLoading = true;
        if (scope.chatApiParams.loadMoreConversations) {
          chatService
            .getUserConversations(
              scope.chatApiParams.pageNumberOfConversations,
              scope.chatApiParams.pageSizeOfConversations,
              scope.chatLibrary.friendsDict
            )
            .then(
              function success(data) {
                let userIds = [];
                const { friendsDict } = scope.chatLibrary;
                userIds = conversationsUtility.getUserIdsNotInFriendsDict(data, friendsDict);

                scope.chatLibrary.chatLayout.isChatLoading = false;
                if (data && data.length > 0) {
                  scope.buildChatUserListByConversations(data);
                  scope.chatApiParams.pageNumberOfConversations++;
                  chatUtility.updateScrollbar(chatUtility.chatLayout.scrollbarClassName);
                  if (data.length < scope.chatApiParams.pageSizeOfConversations) {
                    scope.chatApiParams.loadMoreConversations = false;
                    scope.chatApiParams.loadMoreFriends = true;
                    scope.chatApiParams.pageNumberOfConversations = 1;
                    getFriends();
                  }
                } else {
                  scope.chatApiParams.loadMoreConversations = false;
                  scope.chatApiParams.loadMoreFriends = true;
                  scope.chatApiParams.pageNumberOfConversations = 1;
                  getFriends();
                }
                contactsService.getUserContacts(userIds, friendsDict);
                return usersService.getUserInfo(userIds, friendsDict);
              },
              function error() {
                scope.chatLibrary.chatLayout.isChatLoading = false;
                $log.debug('---error from get Conversations in lazyLoadDirective.js---');
              }
            );
        }
        if (scope.chatApiParams.loadMoreFriends) {
          getFriends();
        }
      };

      scope.callbackScrollStart = function () {
        scope.$broadcast('Roblox.Chat.ConversationListScroll');
        $document.triggerHandler('HoverPopover.EnableClose');
      };

      element.mCustomScrollbar({
        autoExpandScrollbar: false,
        scrollInertia: 5,
        contentTouchScroll: 1,
        mouseWheel: {
          preventDefault: true
        },
        callbacks: {
          onTotalScrollOffset: 100,
          onTotalScroll: scope.callbackLazyLoad,
          onOverflowYNone: scope.callbackLazyLoad,
          onScrollStart: scope.callbackScrollStart
        }
      });
    }
  };
}

chatModule.directive('lazyLoad', lazyLoad);

export default lazyLoad;
