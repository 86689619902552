import chatModule from '../chatModule';

const notificationsName = {
  ChatNotifications: 'ChatNotifications',
  FriendshipNotifications: 'FriendshipNotifications',
  PresenceNotifications: 'PresenceNotifications',
  ChatPrivacySettingNotifications: 'ChatPrivacySettingNotifications',
  UserTagNotifications: 'UserTagChangeNotification',
  VoiceNotifications: 'VoiceNotifications'
};

chatModule.constant('notificationsName', notificationsName);

export default notificationsName;
